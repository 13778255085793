@import "basics";

.header {
  display: flex;
  flex-direction: row;
}

.screenManagementButtons {
  margin-left: auto;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  margin-right: 5px;
}

.screenManagement {
  position: relative;
  margin-left: 20px;
  margin-top: 20px;
}
.screenManagement h1 {
  font-weight: 500;
}

.brightnessIcon,
.sendIcon,
.addIcon {
  height: 1.5em;
  width: 1.5em;
  fill: #ffffff4d;
  transition: fill 300ms ease-in-out;

  .brightnessButton:hover & {
    fill: #ffffff;
  }
}
