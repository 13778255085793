@import "basics";

.addDevice h2 {
  color: black;
}
//move to main.scss
// .ant-select-selector,
// .ant-picker,
// .ant-select {
//   //   background: blueviolet !important;
//   background: $blue !important;
//   border: 1px solid #0b0b56 !important;
// }
