@import "basics";

.noAlerts {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.noAlertsIcon {
  height: 130px;
}

.noAlertsTitle {
  font-family: $font-muli;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: $white;
}

.warningMessage {
  background-color: $warning;
  height: 32px;
  width: 360px;
  padding: 7px 18px;
}
