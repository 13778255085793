@import "basics";

.devices {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: 20px;
}

.devicesMainPage h3 {
  font-weight: 500;
}
