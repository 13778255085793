@import "basics";

.alertItem {
  width: 100%;
  border-bottom: 1px solid $border-dark;
  display: flex;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $white;
  font-family: $font-muli;
  font-size: 13px;
  padding: 10px 0;

  &:hover {
    cursor: pointer;
    background-color: $bg;
  }
  h4 {
    padding-top: 5px;
    text-transform: capitalize;
  }
}
.circle {
  width: 48px;
  height: 48px;
  padding: 12px;
  border-radius: 24px;
  background-color: $border-dark;
  display: flex;
  justify-content: center;
  align-items: center;
}
.alertIcon {
  width: 24px;
  height: 24px;
  fill: $white;
  padding: 2px 3px;
}
.alertContent {
  margin-left: 16px;
}
.alertTitle {
  font-size: 13px;
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 4px;
}
.alertLocationName {
  opacity: 0.5;
  color: $white;
}
.AlertStartTime {
  opacity: 0.5;
  font-size: 13px;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
@-webkit-keyframes blink {
  50% {
    opacity: 0;
  }
}
.blink {
  animation: blink 1s ease-in-out 0s infinite;
  -webkit-animation: blink 1s ease-in-out 0s infinite;
}
