@import "basics";

.root {
  display: flex;
  flex-direction: column;
  color: $white;
  font-size: 13px;

  label {
    color: white;
    opacity: 0.5;
    font-size: 10px;
    text-transform: uppercase;
  }

  h4 {
    font-size: 16px;
    font-weight: 900;
    opacity: 1;
    margin-bottom: 13px;
  }
}

.details {
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
}

.detail {
  flex: 0 0 33%;
  display: flex;
  flex-direction: column;
}

.timeline {
  border: 1px solid $border-dark;
  background: $bg; //$border-dark
  border-radius: 10px;
  padding-top: 20px;
  min-width: 600px;
}

.alertContent {
  margin-left: 16px;
}
.alertTitle {
  font-size: 13px;
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 4px;
}
.alertLocationName {
  opacity: 0.5;
  color: $white;
}
.AlertStartTime {
  opacity: 0.5;
  font-size: 13px;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
@-webkit-keyframes blink {
  50% {
    opacity: 0;
  }
}
.blink {
  animation: blink 1s ease-in-out 0s infinite;
  -webkit-animation: blink 1s ease-in-out 0s infinite;
}
