@import "basics";

.frame {
  display: flex;
  flex-direction: row;
}

.mainPage {
  flex: 1;
  min-height: 100vh;
  margin-left: $sidebar-width;

  @media #{$desktop} {
    margin-right: $alerts-feed-width;
  }
}
