@import "basics";

.root {
  display: flex;
  flex-direction: column;
}

.preview {
  width: 240px;
  background-color: $bg-darkest;
  align-content: center;
  border-radius: 8px;
  border: 1px solid $blue-2;
  margin: 10px 0;
  overflow: hidden;
  position: relative;
}

.preview img {
  width: 100%;
}

.textPreview {
  position: absolute;
  right: 0;
  top: 0;
  width: 160px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 28px;
  white-space: nowrap;
  overflow: hidden;
}

.predefined {
  display: flex;
  flex-wrap: wrap;
}

.fontSizes {
  display: flex;
  flex-wrap: wrap;
}

.predefinedBtn {
  margin: 2px;
  height: 24px;
}

.marquee {
  flex: 0 0 auto;
  min-width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation-name: scroll;
  animation-duration: 10s;
  animation-play-state: running;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 0;
  animation-direction: normal;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
