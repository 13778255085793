@import "basics";

.page {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.root {
  border-radius: 4px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.4);
  background: $bg-dark-light;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media #{$desktop}, #{$tablet} {
    padding: 50px;
    width: 524px;
    text-align: center;
    display: flex;
  }
}

.form {
  min-width: 300px;
  text-align: left;
}

.button {
  width: 100%;
  flex: 1;
  margin: 10px 0 0;
}

.ob {
  width: 150px;
  margin: 10px 0;
}
