@import "basics";
@import "reset";
@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,400;1,500&display=swap");

html {
  font-family: $font-muli;
  background: $bg-color;
  width: 100vw;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-muli;
  line-height: 1.5;
  font-size: 14px;
  font-feature-settings: "tnum", "tnum";
  background: $bg-color;
  color: white !important;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

h1 {
  font-size: 32px;
  font-weight: 900;
  color: white;
}

h2 {
  font-size: 24px;
  font-weight: 900;
  color: white;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  color: white;
}

h4 {
  font-size: 14px;
  font-weight: 700;
  opacity: 0.5;
  color: white;
}

.container {
  margin: 0 auto;
  padding: 0 15px;

  @media #{$desktop} {
    max-width: 95%;
  }

  @media #{$tablet} {
    max-width: 95%;
  }
}

.ant-select-selector,
.ant-picker {
  border: 1px solid rgba($white, 0.15) !important;
  background: $bg-darkest !important;
  color: $white !important;
  border-radius: 4px;
  min-height: 48px !important;
  width: 100%;
  margin: 10px 0;
}

.ant-picker-clear {
  background: $bg-darkest !important;
  color: rgba($white, 0.8);
}

.ant-select-selection-search,
.ant-select-selection-item,
.ant-select-selection-placeholder {
  height: 45px !important;
  line-height: 45px !important;
}

.ant-select-arrow,
.ant-picker-suffix {
  color: rgba($white, 0.8);
}

.ant-picker-input > input {
  color: $white;
}

.ant-tag {
  margin: 3px 6px 3px 0;
}

.ant-checkbox-wrapper {
  color: black;
  margin-bottom: 5px;
  margin-right: 5px;
}

.ant-notification {
  z-index: 999999;
}

.ant-table-thead > tr > th:not(:first-child) {
  text-transform: capitalize;
  text-align: center;
}

.ant-table-tbody > tr > td:not(:first-child) {
  text-align: center;
}

.twitter-picker {
  div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.ant-picker-input > input[disabled] {
  color: rgba(white, 0.5);
}

.darkmode {
  color: white;

  .ant-checkbox-wrapper {
    color: white;
  }

  .ant-table {
    border: 1px solid $border-dark;
    background: $bg-dark-lighter;
    box-shadow: 0 3px 12px 0 rgba($black, 0.4);
    color: white;

    .ant-table-thead > tr > th {
      background: $bg-dark-lighter;
      color: white;
      border-bottom: 1px solid $border-dark;
    }

    td {
      background: $bg-dark-lighter;
      transition: background-color 300ms ease-in-out;
      border-bottom: 1px solid $border-dark;

      &:hover {
        background: red;
      }
    }
    tr {
      background: $bg-dark-lighter;
      transition: background-color 300ms ease-in-out;
      border-bottom: 1px solid $border-dark;

      &:hover {
        background: $border-dark;
      }
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: $border-dark;
  }
}

.ant-tabs-tab-btn {
  color: white;
}

.ant-table-filter-trigger-container-open,
.ant-table-filter-trigger-container:hover,
.ant-table-thead
  th.ant-table-column-has-sorters:hover
  .ant-table-filter-trigger-container:hover,
.ant-table-thead
  th.ant-table-column-has-sorters:hover
  .ant-table-filter-trigger-container {
  background: $border-dark;
}

.ant-checkbox + span {
  color: white;
}

.ant-timeline {
  color: white;
}
