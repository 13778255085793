@import "basics";

.alerts {
  width: $alerts-feed-width;
  background-color: $blue-2;
  position: fixed;
  height: 100%;
  right: 0;
  padding: 1vh;
  z-index: 2;
  display: flex;
  flex-direction: column;

  @media #{$mobile}, #{$tablet} {
    display: none;
  }

  h2 {
    padding-bottom: 5px;
  }
}

.alertList {
  overflow: auto;
  flex: 1;
}
