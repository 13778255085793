@import "basics";

.device {
  width: 240px;
  padding-top: 10px;
  margin-right: 20px;
}
.deviceScreen {
  width: 240px;
  min-height: 80px;
  background-color: $bg-darkest;
  align-content: center;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid $blue-2;
  position: relative;
}

.screenshot {
  height: 100%;
  width: 100%;
  transition: opacity 300ms ease-in-out;
}

.loading img {
  opacity: 0.4;
}

.loadingIndicator {
  position: absolute;
  top: 5px;
  left: 5px;
}

.devicesCheckbox {
  margin-left: 10px;
  margin-top: 5px;
}

.statuslight {
  width: 20px;
  height: 20px;
  background-color: $green-blue;
  border-radius: 100%;
  position: absolute;
  right: 5px;
  top: 5px;
}
.deviseHeader {
  display: flex;

  h4 {
    padding-top: 7px;
    padding-left: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
