@import "basics";

.root {
  height: 38px;
  max-height: 38px;
  border-radius: 4px;
  border: 1px solid;
  margin: 10px 5px;
  padding: 0 10px;
  transition: opacity 300ms ease-in-out, transform 100ms ease-in-out;
  opacity: 0.85;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 1;
    color: white;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:active {
    transform: scale(0.98);
  }
}

.csvButton {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.default {
  border: 1px solid rgba($white, 0.15);
  background: $bg-darkest;
}
.primary {
  background: $primary;
  border-color: $primary-bright;
}

.danger {
  background: $danger;
  border-color: $danger-bright;
}

.good {
  background: $success;
  border-color: $success-bright;
}
