@import "basics";

.configurationItem {
  width: 240px;
  background-color: $bg-darkest;
  align-content: center;
  border-radius: 8px;
  border: 1px solid $blue-2;
  margin: 10px 0;
  overflow: hidden;
  opacity: 0.4;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.isActive {
  opacity: 1;
}

.configurationItem img {
  width: 100%;
  margin: 0;
  padding: 0;
}

.content {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
}

.icons {
  margin-left: auto;
  display: flex;
  flex-direction: row;
}

.iconSvg {
  height: 16px;
  width: 16x;
  fill: white;
}
