$greenish-turquoise: #00e9a0;
$greenish-turquoise-2: #00bf82;
$light-purple: #a135ff;
$light-purple-2: #b363f9;
$light-purple-3: #f2eaff;
$green-blue: #00d894;
$blue: #0072f5;
$blue-2: #302f4f;
$blue-3: #1890ff;
$black: #000000;
$white: #ffffff;
$white-two: #dedede;
$grey: #999999;
$grey-two: #dfdfdf;
$black-5: rgba(0, 0, 0, 0.05);
$black-7: rgba(0, 0, 0, 0.07);

$bg: #393952;
$bg-gray: #f4f4f4;
$bg-color: #1b1c30;
$bg-darkerer: #1b1b2c;
$bg-darker: #25233f;
$bg-darkest: #191929;
$bg-dark: #2a2a45;
$bg-dark-light: #25233f;
$bg-dark-lighter: #1d1d33;
$border-dark: #43415e;
$border-light: #131224;
$warning: #e83e33;

$green-button: #5cb85c;
$success: #1eb007;
$success-bright: #22c908;
$danger: #ec002d;
$danger-bright: #ff0736;
$warn: #ffae00;
$warn-bright: #f3bd48;
$primary: #0072f5;
$primary-bright: #0f7fff;

$font-muli: "Mulish", "Arial", sans-serif;

// Dimensions
$alerts-feed-width: 360px;
$sidebar-width: 66px;
