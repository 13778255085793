@import "basics";

.sidebar {
  height: 100vh;
  background: $blue-2;
  position: fixed;
  width: $sidebar-width;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
}

.screenLogo,
.LocationsLogo {
  fill: $white;
  opacity: 0.3;
  border-radius: 8px;
  height: 26px;
  z-index: 3;
  flex: 1;
}

.sidebarButtons {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-bottom: 2px;
  z-index: 2;
}
.sidebarButtons:hover,
.selected {
  opacity: 0.3;
  background-color: $blue;
}

.logo {
  height: 32px;
  width: 32px;
  align-self: center;
  margin: 20px 0;
}
