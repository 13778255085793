@import "basics";

.wrapper {
  display: flex;
  max-height: 55vh;
}

.preview {
  flex: 1;
  width: 350px;
  margin-left: 20px;
  overflow-y: scroll;
}

.allConfigurations {
  overflow-y: scroll;
  flex: 1;
  max-width: 260px;
}

.footer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
