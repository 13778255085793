@import "basics";

.root {
}

.optionsList {
  display: flex;
}

.icon {
  height: 1.5em;
  width: 1.5em;
  fill: white;
}
